// Loader
import React from 'react';
import styles from './Loader.module.sass';
import cn from 'classnames';

export interface LoaderProps {
  size?: 24 | 32 | 40 | 48 | 56 | 64;
  className?: string;
  overlay?: boolean;
}

const Loader: React.FC<LoaderProps> = ({ size = 56, className, overlay }) => {
  const LoadingSpinner = (
    <div className={cn(styles[`loader${size}`], className)}>
      <span />
    </div>
  );
  return overlay ? (
    <div className={styles.overlay}>{LoadingSpinner}</div>
  ) : (
    LoadingSpinner
  );
};

export default Loader;
